<template>
    <vue-good-table :columns="columns"
                    :rows="rows"
                    mode="remote"
                    styleClass="vgt-table condensed">
        <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field == 'datedOn'">
                        <span>{{props.row.datedOn | moment("DD-MMM-YYYY")}}</span>
                    </span>
            <!--<span v-else-if="props.column.field == 'actions'">
                <v-icon small class="mr-2" @click.stop="editItem(props.row, 'HistoryForm')">edit</v-icon>
                <v-icon small @click.stop="deleteItem(props.row)">delete</v-icon>
            </span>-->
        </template>
    </vue-good-table>
</template>

<script>
    export default {
        name: "YearMonthTable",
        props:['rows','columns'],
        data(){
            return{

            }
        }
    }
</script>

<style scoped>

</style>